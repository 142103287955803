<template>
  <section class="searchresults_area" :class="{'mt-10': isScroll}">
    <div class="container">
      <div class="searchresults_topber" :class="{'fix-box': showFixFilterBox}">
        <h2 v-if="filteredItems.length">
          <strong>{{$t("sabre.search-result.search-result-to", {dest: destCityName})}}</strong>
          {{$t("sabre.search-result.showing-count-result", {count: filteredItems?.length || 0})}}
        </h2>

         <div class="d-flex align-items-center filter-panel">
            <h3>{{$t("sabre.search-result.filter-result")}}</h3>

            <stops-filter propsClassName="d-flex" v-if="device==='desktop'"/>

            <div class="filterlistbox flex-grow-1 d-flex align-items-center justify-content-between">

              <search-result-filter-panel v-if="device==='desktop'"/>

              <search-result-sort-dropdown v-if="device==='desktop'"/>

            </div>
         </div>
         <div class="btn btn-primary release-fix" @click="() => releaseFilterPanel()"></div>
      </div>

      <search-result-mobile-filter v-if="device==='mobile'"/>

      <content-loading v-if="isLoading" type='banner'/>

      <div v-if="isChgFltPg && !isLoading && device==='desktop' && filteredItems.length">
        <search-result-box-one-way v-for="(item, inx) in filteredItems" :key="`${inx}-oneway`" :data="item"/>
      </div>

      <div v-if="!isChgFltPg && !isLoading && device==='desktop' && filteredItems.length && flightDirection===flightDirectionList[1]">
        <search-result-box-one-way v-for="(item, inx) in filteredItems" :key="`${inx}-oneway`" :data="item"/>
      </div>
      <div v-if="!isChgFltPg && !isLoading && device==='desktop' && filteredItems.length && flightDirection!==flightDirectionList[1]">
        <search-result-box-round-trip v-for="(item, inx) in filteredItems" :key="`${inx}-round`" :data="item"/>
      </div>

      <div v-if="isChgFltPg && !isLoading && device==='mobile' && filteredItems.length">
        <mobile-result-box-one-way v-for="(item, inx) in filteredItems" :key="`${inx}-mobile-oneway`" :data="item"/>
      </div>

      <div v-if="!isChgFltPg && !isLoading && device==='mobile' && filteredItems.length && flightDirection===flightDirectionList[1]">
        <mobile-result-box-one-way v-for="(item, inx) in filteredItems" :key="`${inx}-mobile-oneway`" :data="item"/>
      </div>
      <div v-if="!isChgFltPg && !isLoading && device==='mobile' && filteredItems.length && flightDirection!==flightDirectionList[1]">
        <mobile-result-box-round-trip v-for="(item, inx) in filteredItems" :key="`${inx}-mobile-round`" :data="item"/>
      </div>

      <no-search-result-box v-if="!isLoading && filteredItems.length===0"/>

      <div style="height: 50vh;"></div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'sabre-search-result-area',
  components: {
    ContentLoading: () => import('@/components/atoms/ContentLoading'),

    SearchResultSortDropdown: () => import('./sortContent/searchResultSortDropdown'),
    SearchResultFilterPanel: () => import('./filterPanel/searchResultFilterPanel'),
    SearchResultMobileFilter: () => import('./filterPanel/searchResultMobileFilter'),
    SearchResultBoxRoundTrip: () => import('./searchResultBox/searchResultBoxRoundTrip'),
    SearchResultBoxOneWay: () => import('./searchResultBox/searchResultBoxOneWay'),

    MobileResultBoxRoundTrip: () => import('./searchResultBoxMobile/mobileResultBoxRoundTrip'),
    MobileResultBoxOneWay: () => import('./searchResultBoxMobile/mobileResultBoxOneWay'),

    NoSearchResultBox: () => import('./noSearchResultBox'),
    StopsFilter: () => import('./filterPanel/filterPanelAtom/stopsFilter'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      currentPage: 'GET_CURRENT_PAGE',
      isLoading: 'GET_LOADING_STATE',
      destinationList: 'GET_DESTINATION_IMAGES',
      calendarTypeList: 'GET_SABRE_CALENDAR_TYPE_LIST',
      flightDirectionList: 'GET_SABRE_FLIGHT_DIRECTION_LIST',
      filteredItems: 'GET_SABRE_FLIGHT_FILTERED_ITEMS',
      departmentList: 'GET_SABRE_FLIGHT_DEPARTMENT_LIST',
      searchResult: 'GET_SABRE_FLIGHT_SEARCH_RESULT',
    }),
    isChgFltPg() {
      return this.currentPage === 'sabre-change-flight';
    },
    flightDirection() {
      return this.$route.query.direction;
    },
    destCode() {
      return this.$route.query.toDests?.split(',')[0] || null;
    },
    destinations() {
      const { destinationList } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      return destinationList?.filter((dest) => dest.name) || [];
    },
    destCityName() {
      const { destinations, destCode } = this;
      return destinations.find((d) => d.code === destCode)?.name_Loc?.content || '';
    },
    destCityEnName() {
      const { destinations, destCode } = this;
      return destinations.find((d) => d.code === destCode)?.name || '';
    },
  },
  watch: {
    $route: 'storeSearchDataFromQuery',
    searchResult() {
      // whenever update the search result, get all search filter condition
      this.$store.dispatch('EXTRACT_SABRE_FLIGHT_FILTER_CONDITION');
    },
  },
  data() {
    return {
      isScroll: false,
      showFixFilterBox: false,
    };
  },
  mounted() {
    this.$root.$on('show-fix-filter-panel', this.showFixFilterPanel);
    this.storeSearchDataFromQuery();
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll(event) {
      if (this.device === 'desktop' && !this.isChgFltPg) this.isScroll = Number(event.target.scrollTop) > 150;
      if (Number(event.target.scrollTop) < 150) {
        this.showFixFilterBox = false;
      }
    },
    async storeSearchDataFromQuery() {
      const queryBody = this.$route.query;
      const { adults, children, babies, seniors,
        direction, fromDests, toDests, fromDests1, toDests1,
        calendar, startDate, endDate, nights, weekend, midweek, fullMonth,
        isDirectOnly, cabinPrefs, changeFlightID } = queryBody;

      if (!this.getCompositionCondition(adults, children, babies, seniors)) return false;
      if (!this.getDateCondition(calendar, startDate, endDate, fullMonth, nights, weekend, midweek)) return false;
      if (!this.getDestCondition(direction, fromDests, toDests, fromDests1, toDests1)) return false;

      const cabinList = cabinPrefs.split(',');
      if (!cabinList.length) return false;
      if (!cabinList.every((item) => this.departmentList.includes(item))) return false;

      // store direction only and cabinPrefs

      this.$store.commit('SET_SABRE_SELECTED_FLIGHT_DIRECTION', direction);
      this.$store.commit('SET_SABRE_IS_CHECKED_DIRECT_FLIGHT_ONLY', isDirectOnly === 'true');
      this.$store.commit('SET_SABRE_FLIGHT_DEPARTMENT', { all: true, item: cabinPrefs.split(',') });

      if (changeFlightID) {
        delete queryBody.changeFlightID;
      }
      await this.$store.dispatch('FETCH_SABRE_FLIGHT_SEARCH_RESULT', new URLSearchParams(queryBody).toString());

      if (changeFlightID) {
        await this.$store.dispatch('FETCH_SABRE_CHANGE_FLIGHTS_LIST', changeFlightID);
      }
      return true;
    },
    getCompositionCondition(ad, ch, bb, sn) {
      this.$store.commit('SET_SABRE_ADULTS_FOR_SEARCH', +ad);
      this.$store.commit('SET_SABRE_CHILDREN_FOR_SEARCH', +ch);
      this.$store.commit('SET_SABRE_BABIES_FOR_SEARCH', +bb);
      this.$store.commit('SET_SABRE_SENIORS_FOR_SEARCH', +sn);
      return +ad || +ch || +bb || +sn;
    },
    getDateCondition(cType, start, end, fullMonth, nights, weekend, midweek) {
      // ['exactDates', 'specificDateRange', 'fullMonthDateRange']

      if (!this.calendarTypeList.includes(cType)) return false;

      this.$store.commit('SET_SABRE_CALENDAR_TYPE', cType);
      let cond1 = false; // exact date condition
      if (cType === this.calendarTypeList[0] && start && new Date(start) !== 'Invalid Date') {
        this.$store.commit('SET_SABRE_EXACT_DATE_RANGE', [start, end]);
        cond1 = true;
      }
      let cond2 = false; // specific date range condition
      if (cType === this.calendarTypeList[1] && start && end && new Date(start) !== 'Invalid Date' && new Date(end) !== 'Invalid Date') {
        this.$store.commit('SET_SABRE_SPECIFIC_DATE_RANGE', [start, end]);
        this.$store.commit('SET_SABRE_NIGHTS_FOR_SEARCH', +nights);
        this.$store.commit('SET_SABRE_WEEKEND_TARGETED_FOR_SEARCH', weekend === 'true');
        this.$store.commit('SET_SABRE_MIDWEEK_TARGETED_FOR_SEARCH', midweek === 'true');
        cond2 = true;
      }

      const months = fullMonth?.split(',');
      let cond3 = false; // full months condition
      if (cType === this.calendarTypeList[2] && months?.length) {
        this.$store.commit('SET_SABRE_FULL_MONTHS', { all: true, item: months });
        this.$store.commit('SET_SABRE_NIGHTS_FOR_SEARCH', +nights);
        this.$store.commit('SET_SABRE_WEEKEND_TARGETED_FOR_SEARCH', weekend === 'true');
        this.$store.commit('SET_SABRE_MIDWEEK_TARGETED_FOR_SEARCH', midweek === 'true');
        cond3 = true;
      }
      return cond1 || cond2 || cond3;
    },
    getDestCondition(dir, from, to, from1, to1) {
      // ['roundTrip', 'oneWay', 'multiCity']

      if (!this.flightDirectionList.includes(dir)) return false;

      let cond1 = false; // round trip and one way condition
      if (dir !== this.flightDirectionList[2] && from.split(',')?.length && to.split(',')?.length) {
        this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: true, clean: true });
        from.split(',').forEach((code) => {
          this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: true, code, clean: false, isMultiCity: false });
        });

        this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: false, clean: true });
        to.split(',').forEach((code) => {
          this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: false, code, clean: false, isMultiCity: false });
        });
        cond1 = true;
      }
      let cond2 = false; // multi city condition
      if (dir === this.flightDirectionList[2] && from.split(',')?.length && to.split(',')?.length && from1.split(',').length && to1.split(',').length) {
        this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: true, clean: true });
        from.split(',').forEach((code) => {
          this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: true, code, clean: false, isMultiCity: false });
        });

        this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: false, clean: true });
        to.split(',').forEach((code) => {
          this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: false, code, clean: false, isMultiCity: false });
        });

        this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: true, clean: true, isMultiCity: true });
        from1.split(',').forEach((code) => {
          this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: true, code, clean: false, isMultiCity: true });
        });

        this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: false, clean: true, isMultiCity: true });
        to1.split(',').forEach((code) => {
          this.$store.commit('SET_SABRE_CHOOSE_DESTINATION', { from: false, code, clean: false, isMultiCity: true });
        });
        cond2 = true;
      }
      return cond1 || cond2;
    },
    showFixFilterPanel(t) {
      this.showFixFilterBox = t;
    },
    releaseFilterPanel() {
      this.showFixFilterBox = false;
      this.$root.$emit('show-fix-filter-panel', false);
    },
  },
};
</script>

<style scoped>
  .searchresults_area.mt-10 {
    margin-top: 10rem;
  }
  .searchresults_topber.fix-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: linear-gradient(90deg, #059ad5 0%, #0261ab 100%);
    padding: 30px;
  }
  .searchresults_topber.fix-box .filter-panel {
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
  }
  .searchresults_topber.fix-box h2, .searchresults_topber.fix-box h2 strong{
    color: white;
  }
</style>
